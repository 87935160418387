import React, { Component } from 'react';

import MdTrash from 'react-ionicons/lib/MdTrash';
import IosAddCircleOutline from 'react-ionicons/lib/IosAddCircleOutline';
import IosRemoveCircleOutline from 'react-ionicons/lib/IosRemoveCircleOutline';

import * as endpoints from './../../../../Service/endpoints';

class CartItem extends Component {

    getProductUrl = (product) => {

        if (typeof product.category != "undefined") {
            return endpoints.getRoute(`${product.category.slug}/${product.subCategory.slug}/${product.slug}`);
        } else {
            return "#";
        }

    }
    
    
    render() {
        // console.log('list', this.props.product.productInfo.category.slug);
        return (
            <div className="row">
                <div className="col-12 col-md-3 text-center" style={{ float: 'left' }}>
                    <a href={this.getProductUrl(this.props.product.productInfo)}>
                        <img style={{ maxHeight: 'inherit' }} src={this.props.product.productInfo.featuredImage}
                            alt="..." className="img-thumbnail" />
                    </a>
                </div>
                <div className="col-12 col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <a href={this.getProductUrl(this.props.product.productInfo)} className="mt-2 font-weight-bold">{this.props.product.productInfo.name}</a>
                                    <span style={{ backgroundColor: this.props.product.productInfo.category.slug === "premium" && "#31a04c" ,color:'#fff', borderRadius:10 , padding:3, margin:5}}>
                                        {this.props.product.productInfo.category.slug === "premium" && (
                                            <a style={{ textDecoration: "none", color: "#fff", fontSize:12 }}>Premium</a>
                                        )}
                                    </span>
                                </div>
                                <div className="col-12 m-auto" style={{ position: 'relative' }} >
                                    <div className="row">
                                        <div className="col-8 col-md-6">
                                            <span>Quantity : </span>
                                            <IosRemoveCircleOutline className="cursor-pointer" onClick={(e) => this.props.manageQuantity('sub', this.props.product.productSlug)} />
                                            <span>&nbsp;{this.props.product.quantity}&nbsp;</span>
                                            <IosAddCircleOutline className="cursor-pointer" onClick={(e) => this.props.manageQuantity('add', this.props.product.productSlug)} />
                                        </div>
                                        <div className="col-4 col-md-6 text-right">
                                            <MdTrash className="cursor-pointer" onClick={(e) => this.props.deleteProductFromCart(this.props.product.productSlug)} fontSize="24px" color="#645e55" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <div className="col-12 mt-2" style={{ border: '1px solid #e8564f', borderRadius: '4px' }} >
                            <h6 className="mt-1 mb-1" style={{ color: '#e8554e' }}>
                                <span className="ml-3">Free delivery in :</span>
                                <span className="ml-1 font-weight-bold">3-5 Days</span>
                            </h6>
                        </div> */}
                        <div className="col-12 mt-2">
                            <div className="row">
                                {this.props.product.productInfo.category.slug != "seasonal"&&<div className="col-4">
                                    <span>Tenure</span>
                                    <span className="text-black-50" style={{ display: 'block' }}>{this.props.product.tenure} Months</span>
                                </div>}
                                <div className="col-4">
                                    <span>{this.props.product.productInfo.category.slug != "seasonal"?'Monthly Rent':'Total Rent'}</span>
                                    <span className="text-black-50" style={{ display: 'block' }}>Rs { parseFloat(this.props.product.monthlyRent / 1.18).toFixed(0)}</span>
                                </div>
                                {this.props.product.productInfo.category.slug != "premium"&&this.props.product.productInfo.category.slug != "seasonal"&&<div className="col-4">
                                    <span>{this.props.product.isMedical ? "GST" : "Security Deposit"}</span>
                                    <span className="text-black-50" style={{ display: 'block' }}>Rs {this.props.product.isMedical ? (parseFloat(this.props.product.monthlyRent * 0.18)).toFixed(1) : parseFloat(this.props.product.monthlyRent / 1.18).toFixed(0) * 1}</span>
                                </div>}
                                {this.props.product.productInfo.category.slug === "premium"&&<div className="col-4">
                                    <span>{this.props.product.isMedical ? "GST" : "Advance"}</span>
                                    <span className="text-black-50" style={{ display: 'block' }}>Rs {parseFloat((this.props.product.monthlyRent / 1.18)*11).toFixed(0)}</span>
                                </div>}
                                {this.props.product.productInfo.category.slug === "seasonal"&&<div className="col-4">
                                    <span>{this.props.product.isMedical ? "GST" : "Security Deposit"}</span>
                                    <span className="text-black-50" style={{ display: 'block' }}>Rs 0</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CartItem;