import React, { Component } from "react";

class BannerSliderComponent extends Component {
  render() {
    const banners = [
      {
        id: 1,
        src: "https://voorent.com/voorent-storage/banners/voorent-banner-1.jpg",
        alt: "First slide",
      },
      {
        id: 2,
        src: "https://voorent.com/voorent-storage/banners/voorent-banner-2-xlarge.png",
        srcSet: `
          https://voorent.com/voorent-storage/banners/voorent-banner-2-small.png 640w,
          https://voorent.com/voorent-storage/banners/voorent-banner-2-medium.png 1027w,
          https://voorent.com/voorent-storage/banners/voorent-banner-2-large.png 1141w,
          https://voorent.com/voorent-storage/banners/voorent-banner-2-xlarge.png 1920w
        `,
        sizes: `
          (max-width: 640px) 100vw,
          (max-width: 1027px) 90vw,
          (max-width: 1440px) 70vw,
          50vw
        `,
        alt: "Second slide",
      },
      {
        id: 3,
        src: "https://voorent.com/voorent-storage/banners/voorent-banner-3-xlarge.png",
        srcSet: `
          https://voorent.com/voorent-storage/banners/voorent-banner-3-small.png 640w,
          https://voorent.com/voorent-storage/banners/voorent-banner-3-medium.png 1027w,
          https://voorent.com/voorent-storage/banners/voorent-banner-3-large.png 1141w,
          https://voorent.com/voorent-storage/banners/voorent-banner-3-xlarge.png 1920w
        `,
        sizes: `
          (max-width: 640px) 100vw,
          (max-width: 1027px) 90vw,
          (max-width: 1440px) 70vw,
          50vw
        `,
        alt: "Third slide",
      },
    ];

    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide m-auto"
        data-ride="carousel"
        style={{ width: "100%", paddingTop: "80px" }}
      >
        <ol className="carousel-indicators">
          {banners.map((banner, index) => (
            <li
              key={banner.id}
              data-target="#carouselExampleIndicators"
              data-slide-to={index}
              className={index === 0 ? "active" : ""}
            ></li>
          ))}
        </ol>

        <div className="carousel-inner">
          {banners.map((banner, index) => (
            <div
              key={banner.id}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <img
                className="d-block w-100"
                style={{ minHeight: "220px", width: "100%", height: "auto" }}
                loading="lazy"
                src={banner.src}
                srcSet={banner.srcSet || ""}
                sizes={banner.sizes || ""}
                alt={banner.alt}
              />
            </div>
          ))}
        </div>

        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

export default BannerSliderComponent;
