
import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';
import ReportIssue from './../../Generic/Modal/reportIssue';
import * as service from './../../../Service/service';
import { connect } from 'react-redux';
import OrderExtendModal from './../Models/OrderExtendModal';
import UnPaidInvoice from '../Models/UnpaidInvoice';
import Pagination from '../../Generic/Element/Pagination';
import { paginate } from '../../../Service/paginate';

class OrderComponent extends Component {

    state = {
        orders: [],
        slugs: [],
        isLoaded: false,
        selectedOrder: '',
        razorpayInfo: {},
        showUnpaidModal: false,
        razorPayKey:'',
        isupdating: false,
        pageSize:4,
        currentPage :1 ,
        isExtending :false
    }

    getRazorPayKeys=()=>{

        service.razorPayKeys().then((response)=>{
            this.setState({
                razorPayKey: response.data
            });
            
        }, (error) => {
            console.log(error);
        });
    }

    generateRazorPayOrderId = (orderNumber) => {


        service.getRazorpayOrderId(orderNumber).then((response) => {
            this.setState({
                ...this.state,
                razorpayInfo: response.data,
                selectedOrder: orderNumber
            });

            setTimeout(() => {
                this.initiatePayment();
            }, 100);
        }, (error) => {
            console.log(error);
        });
    }

    initiatePayment = () => {

        //rzp_test_QOYFwQGaw3RoOz
        //rzp_live_mYfQb0kL1BGvYD
        var options = {
            "key": this.state.razorPayKey,
            "amount": this.state.razorpayInfo.amount_due,
            "currency": this.state.razorpayInfo.currency,
            "name": "Voorent Pvt. Ltd",
            "description": "",
            "image": "https://voorent.com/voorent-storage/application/voorent.png",
            "order_id": this.state.razorpayInfo.id,
            "handler": (response) => {
                this.updateRazorpayPayments(response);
            },
            "modal": {
                "ondismiss": () => {
                    this.getAllOrders();
                }
            },
            "prefill": {
                "name": `${this.props.userInfo.firstName} ${this.props.userInfo.lastName}`,
                "email": `${this.props.userInfo.email}`,
                "contact": `${this.props.userInfo.contact}`
            },
            "theme": {
                "color": "#01497f"
            }
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    cancelOrder = (orderNumber) => {
        service.cancelOrder(orderNumber).then((response) => {
            if (response.data) {
                this.getAllOrders();
            }
        }, (error) => {
            console.log(error);
        });
    }

    updateRazorpayPayments = (paymentUpdate) => {

        this.setState({
            ...this.state,
            isupdating:true
        });

        let obj = {
            razorpayPaymentId: paymentUpdate.razorpay_payment_id,
            razorpayOrderId: paymentUpdate.razorpay_order_id,
            razorpaySignature: paymentUpdate.razorpay_signature,
            orderNumber: this.state.selectedOrder
        };

        service.updatePayment(obj).then((response) => {

            this.setState({
                ...this.state,
                paymentConfirmation: response.data,
                isLoaded:true
            });

            if (response.data) {
                //this.createSubscription(this.state.selectedOrder);
                this.getAllOrders();

            } else {
                //payment failure
            }

        }, (error) => {
            console.log(error);
        });
    }
    createSubscription=(orderNumber)=>{

        service.getRazorpaySubscriptionId(orderNumber).then((response) => {
            this.setState({
                ...this.state
            });

        }, (error) => {
            console.log(error);
        });


    }


    getAllOrders = () => {

        service.listOrders().then((response) => {

            let slugs = [];

            response.data.map((order) => {

                order.productList.map((product) => {
                    if (slugs.indexOf(product.productSlug) == -1) {
                        slugs.push(product.productSlug);
                    }
                });

            });

            service.cartProductInfo(slugs).then((resp) => {

                response.data.map((order) => {

                    order.productList.map((product) => {

                        resp.data.map((prod) => {
                            if (prod.slug == product.productSlug) {
                                product.info = prod;
                            }
                        });

                    });

                });

                this.setState({
                    ...this.state,
                    orders: response.data,
                    isLoaded: true,
                    isupdating: false
                });


            }, (error) => {
                console.log(error);
            });


        }, (error) => {
            console.log(error);
        });

    }

    checkInvoicePaid =(order)=>{
        this.setState({isExtending:true});
        
        service.invoiceIsPaid(order.orderNumber).then((response)=>{
            this.setState({
                ...this.state,
                invIsPaid: response.data
            });

            if (response.data) {
                this.props.showUnpaidModal();
                this.setState({isExtending:false});
        
            } else {
                this.handleExtend(order.orderNumber,order.orderDate,order.expirayDate);
                this.setState({isExtending:false});  
            }

        }, (error) => {
            console.log(error);
        })
    }

    componentWillMount() {
        this.getAllOrders();
        this.getRazorPayKeys();
    }

    handleIssue = (type, orderNumber, productSlug = '') => {
        console.log(type, orderNumber, productSlug);

        if (type === "order") {
            this.props.showOrderIssueModal({
                type : type,
                issueItemId : orderNumber
            });
        }

        if (type === 'product') {
            this.props.showProductIssueModal({
                type : type,
                issueItemId : orderNumber,
                productSlug : productSlug
            });
        }
    };

    handleExtend = (orderNumber,orderDate,expirayDate) => {
        this.props.showOrderExtend({
            orderNumber : orderNumber,
            orderDate : orderDate,
            expirayDate : expirayDate
        });
    };

    setExpiryDate = (expirayDate) => {

        let today = new Date();
        let expiry = new Date(expirayDate);
        let showtill = new Date(expirayDate);
        showtill.setDate(expiry.getDate() + 30);

        if (today <= showtill && today >= expiry) {
            return true;
        }
        else {
            return false;
        }
    }

    componentDidUpdate(){
       if( this.props.updateOrderOnExtend.updateOrder==true){
           this.getAllOrders();
           this.props.updateOrderFalse();
       }
    }

    handlePageChange=page=>{
        this.setState({currentPage:page});
      }

    statusColor=(orderStatus)=>{
        if(orderStatus=='DELIVERED'){
            return "text-success ml-3";
        }
        else if(orderStatus == 'PAYMENT_AWAITED' || orderStatus == 'ENQUIRY_AWAITED'){
            return "text-warning ml-3";
        }
        else{
            return "text-danger ml-3"
        }
    }

    render() {

        const totalOrders=paginate(this.state.orders,this.state.currentPage,this.state.pageSize);
        return (
            <React.Fragment>
                
                <div className="row mt-3">
                    <div className="col-12">
                        <H2Title value="Your Orders" />

                        <div className="row element-border mt-3 mb-3 mr-1 ml-1" style={{ minHeight: '250px' }}>


                            {
                                !this.state.isLoaded && (<div className="col-12 m-auto">
                                    <div className="row">
                                        <div className="col-12 text-center mt-5">
                                            <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                        </div>
                                    </div>
                                </div>)
                            }


                            {
                                this.state.isLoaded && this.state.orders.length == 0 && (
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <img height="150px" src="./svg/order.svg" />
                                                <h5 className="mt-3 mb-5">No orders till now, Your orders will be shown here once you rent somthing with us.</h5>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                totalOrders.map((order, oIndex) => (
                                    <React.Fragment key={oIndex}>
                                        <div className="col-12 p-3">
                                            <div className="row">
                                                <div className="col-8 col-md-10">
                                                    <div>
                                                        <span className="text-uppercase">Order Id : </span>
                                                        <span className="text-black-50">{order.orderNumber}</span>
                                                        <span className={this.statusColor(order.orderStatus)}>{order.orderStatus}</span>
                                                    </div>

                                                    <div>       
                                                        <span className="text-uppercase">Placed On : </span>
                                                        <span className="text-black-50">{new Date(order.orderDate).toDateString()}</span>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-md-2 text-right">
                                                    {
                                                        order.orderStatus == 'PAYMENT_AWAITED' && (
                                                            <button onClick={() => this.generateRazorPayOrderId(order.orderNumber)} className="btn btn-success btn-sm btn-block" disabled={this.state.isupdating}>
                                                                {
                                                                    this.state.isupdating?<div><span className="ml-2">Processing &nbsp; </span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Pay Now"
                                                                }
                                                            </button>
                                                        )
                                                    }
                                                    {
                                                        order.orderStatus == 'DELIVERED' && (
                                                            <React.Fragment>

                                                               {    
                                                                    this.setExpiryDate(new Date(order.expirayDate)) && 
                                                                        (<button className="btn btn-success btn-sm btn-block" onClick={() => this.checkInvoicePaid(order)} disabled={this.state.isExtending}>
                                                                            {
                                                                            this.state.isExtending?<div><span className="ml-2">Processing &nbsp;</span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Extend"
                                                                            }</button>)
                                                               }    

                                                                <button className="btn btn-danger btn-sm btn-block mt-1" onClick={() => this.handleIssue('order', order.orderNumber)}>Return</button>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    {
                                                        order.orderStatus == 'PAYMENT_AWAITED' && (
                                                            <button onClick={() => this.cancelOrder(order.orderNumber)} className="btn btn-danger btn-sm btn-block"><span>Cancel</span></button>
                                                        )
                                                    }

                                                </div>
                                            </div>

                                            <div className="row mt-2">
                                                <div className="col-12 mb-2">
                                                    <span className="text-uppercase font-weight-bold"> Items in Order </span>
                                                </div>
                                                <div className="col-12">
                                                    {
                                                        order.productList.map((product, pIndex) => (
                                                            <React.Fragment key={pIndex}>
                                                                <div className="row">
                                                                    <div className="col-3 col-md-2 text-center m-auto">
                                                                        <img src={product.info.featuredImage} alt="..." className="img-thumbnail" style={{ maxHeight: '50px' }} />
                                                                    </div>
                                                                    <div className="col-9 col-md-10">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <span className="text-capitalize">{product.info.name}</span>
                                                                                <span className="badge badge-light ml-2" style={{ position: 'relative', top: '-2px', border: '1px solid #DDD' }}>
                                                                                    <span className="text-black-50">Quantity : </span>
                                                                                    <span className="badge badge-light">{product.quantity}</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            {product.info.category.slug!='seasonal'&&<div className="col-6 col-md-3">
                                                                                <span className="d-block">Tenure</span>
                                                                                <span className="text-black-50">{product.tenure} Months</span>
                                                                            </div>}
                                                                            <div className="col-6 col-md-3">
                                                                                <span className="d-block">Monthly Rent</span>
                                                                                <span className="text-black-50">Rs {parseFloat(order.securityDeposit[pIndex].rent/1.18).toFixed(2)}</span>
                                                                            </div>
                                                                            <div className="col-6 col-md-3">
                                                                                <span className="d-block">GST (18%)</span>
                                                                                <span className="text-black-50">Rs {parseFloat(order.securityDeposit[pIndex].rent*0.18/1.18).toFixed(2)}</span>
                                                                            </div>
                                                            
                                                                            <div className="col-6 col-md-3">
                                                                            <span className="d-block">Security Deposit</span>
                                                                            <span className="text-black-50">Rs {parseFloat(order.securityDeposit[pIndex].totalDeposit).toFixed(2)}</span>
                                                                            </div>
                                                                            
                                                                            <div className="col-6 col-md-3">
                                                                                {
                                                                                    order.orderStatus == "DELIVERED" && (
                                                                                        <React.Fragment>
                                                                                            <span className="d-block text-primary" style={{ cursor: 'pointer' }} onClick={() => this.handleIssue('product',order.orderNumber,product.productSlug)}>Report Issue</span>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {pIndex == order.productList.length - 1 ? '' : (<hr />)}
                                                            </React.Fragment>
                                                        ))
                                                    }

                                                </div>
                                            </div>


                                            <div className="row mt-2 mb-2">
                                                <div className="col-12 mb-2 mt-1">
                                                    <span className="text-uppercase font-weight-bold"> Payment Details </span>
                                                </div>
                                                <div className="col-6 col-md-2">
                                                    <span className="d-block">Monthly Rent </span>
                                                    <span className="text-black-50">Rs {parseFloat(order.orderTerms.monthlyBillAmount/1.18).toFixed(2)}</span>
                                                </div>
                                                <div className="col-6 col-md-2">
                                                    <span className="d-block">Security </span>
                                                    <span className="text-black-50">Rs {parseFloat(order.orderTerms.totalDeposit/1.18).toFixed(2)}</span>
                                                </div>
                                                <div className="col-6 col-md-2">
                                                    <span className="d-block">Delivery </span>
                                                    <span className="text-black-50">FREE</span>
                                                </div>
                                                <div className="col-6 col-md-2">
                                                    <span className="d-block">Coupon </span>
                                                    <span className="text-black-50">Rs {order.orderTerms.discount}</span>
                                                    <span style={{ display: order.couponUsed ? 'inline-block' : 'none' }} className="text-black-50">( {order.couponUsed ? order.couponId : ''} )</span>
                                                </div>
                                                <div className="col-6 col-md-2">
                                                    <span className="d-block">Total Paid </span>
                                                    <span className="text-black-50">Rs {order.orderTerms.totalPaid}</span>
                                                </div>
                                                <div className="col-6 col-md-2">
                                                    <span className="d-block">Paid By </span>
                                                    <span className="text-black-50 text-uppercase">{order.orderTerms.totalPaid == 0 ? 'NA' : 'Razorpay'}</span>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <a data-toggle="collapse" className="text-underline-border" href={'#showMore' + oIndex} >
                                                        View Delivery & Order Extension details +
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="collapse" id={"showMore" + oIndex}>

                                                <div className="row mt-2 mb-2">
                                                    <div className="col-12 mb-2 mt-1">
                                                        <span className="text-uppercase font-weight-bold"> Delivery Details </span>
                                                    </div>
                                                    <div className="col-6 col-md-2">
                                                        <span className="d-block">Order Date </span>
                                                        <span className="text-black-50">{new Date(order.orderDate).toDateString()}</span>
                                                    </div>
                                                            <div className="col-6 col-md-2">
                                                                <span className="d-block">Delivery Date </span>
                                                                <span className="text-black-50">{order.deliveryDate != null?(new Date(order.deliveryDate).toDateString()):''}</span>
                                                            </div>
                                                    
                                                            <div className="col-6 col-md-2">
                                                                <span className="d-block">Current Expiry Date </span>
                                                                <span className="text-black-50">{order.expirayDate != null?(new Date(order.expirayDate).toDateString()):''}</span>
                                                            </div>
                                                   
                                                </div>

                                                <div className="row mt-2 mb-2">
                                                    <div className="col-12 mb-2 mt-1">
                                                        <span className="text-uppercase font-weight-bold"> Order Extensions </span>
                                                    </div>
                                                    {
                                                        order.orderExtendList.length == 0 && (
                                                            <div className="col-12">
                                                                <span className="text-black-50"> No order extensions found.</span>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        order.orderExtendList.map((extend, eIndex) => (
                                                            <React.Fragment key={eIndex}>
                                                                <div className="col-6 col-md-3">
                                                                    <span className="d-block">Request Id </span>
                                                                    <span className="text-black-50">{extend.extensionId}</span>
                                                                </div>
                                                                <div className="col-6 col-md-3">
                                                                    <span className="d-block">Extended Months </span>
                                                                    <span className="text-black-50">{extend.extendedMonth} Months</span>
                                                                </div>
                                                                <div className="col-6 col-md-3">
                                                                    <span className="d-block">Expiry Date </span>
                                                                    <span className="text-black-50">{new Date(extend.newExpiry).toDateString()}</span>
                                                                </div>
                                                                <div className="col-6 col-md-3">
                                                                    <span className="d-block">Comments </span>
                                                                    <span className="text-black-50">{extend.comments}</span>
                                                                </div>
                                                                {eIndex == order.orderExtendList.length - 1 ? '' : (<hr />)}
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 mb-2 mt-1">
                                                    <span className="text-uppercase font-weight-bold"> Downloads </span>
                                                </div>

                                                <div className="col-6 col-md-2">
                                                    <a target="_blank" href={'./account/order/' + order.orderNumber}>
                                                        <span className="text-primary" style={{ cursor: 'pointer' }}> Download Summary </span>
                                                    </a>
                                                </div>

                                                {
                                                    order.orderStatus == "DELIVERED" && (
                                                        <div className="col-6 col-md-2">
                                                            <a href="./account?page=invoice" className="text-primary" style={{ cursor: 'pointer' }}> View Invoices </a>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>

                                        {oIndex == this.state.orders.length - 1 ? '' : (<hr />)}
                                    </React.Fragment>
                                ))
                            }
                        {
                                this.state.isLoaded && this.state.orders.length != 0 && (
                                        <div className="ml-3">
                                            <Pagination 
                                            itemsCount={this.state.orders.length} 
                                            pageSize={this.state.pageSize} 
                                            onPageChange={this.handlePageChange}
                                            currentPage={this.state.currentPage}
                                            />
                                        </div>
                                )
                        }
                        </div>
                        
                    </div>
                    <ReportIssue />
                    <OrderExtendModal/>
                                                                 
                    <UnPaidInvoice />
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        userInfo: currentState.userState.userInfo,
        reportIssue: currentState.reportIssue,
        orderExtend: currentState.orderExtend,
        updateOrderOnExtend: currentState.updateOrderOnExtend,
        unpaidInvoiceModal : currentState.unpaidInvoiceModal,
        
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        showOrderIssueModal: (data) => {
            dispatch({ type: 'issue.modal.order.open', data : data });
        },
        showProductIssueModal: (data) => {
            dispatch({ type: 'issue.modal.product.open', data : data })
        },
        showOrderExtend: (data) => {
            dispatch({ type: 'order.modal.extend.open', data : data })
        },
        showUnpaidModal: ()=>{
            dispatch({ type: 'invoice.unpdaid.showModal.true'})
        },
        updateOrderFalse:()=>{
            dispatch({type: 'order.update.extend.false'})
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(OrderComponent);