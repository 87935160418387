import React, { Component } from 'react';
import H2Title from './../../../Generic/Element/Heading';
import HeaderComponent from './../../../Generic/Header/HeaderComponent';
import FooterComponent from './../../../Generic/Footer/FooterComponent';
import BreadCrumbs from './../../../Generic/BreadCrumbs/BreadCrumbs';

import Blog9 from './Blog9';

import { connect } from 'react-redux';

// console.log("path",window.location.pathname)
class Blog9Component extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
        this.props.updateBreadcumbs({
            navigation : [{ name: 'Home', link: './' }, { name: "High-Quality Rented Furniture at Voorent", link: '#' }],
            showSidebar : false,
            sidebar : []
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderComponent />
                <BreadCrumbs />
                <main role="main" className="container mt-5">
                    <div className="row m-auto">
                        <H2Title value={'High-Quality Rented Furniture at Voorent'} />
                        {/* <img
                            style={{ minHeight: "350px" }}
                            className="d-block w-100 lazyload "
                            src="https://voorent.com/voorent-storage/Blog1.jpg"
                            alt="Kitchen">
                        </img> */}
                        <div className="col-12 mt-3">
                            <Blog9 />
                        </div>

                    </div>
                </main>

                <FooterComponent />
            </React.Fragment>
        );
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(null, mapActionsToProps)(Blog9Component);